import React from 'react';
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Box, Button, Card, Divider, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import Iconify from '../common/iconify.component';
import { formatNumber } from '../../utils/numbers.util';
import ROUTE_PATH from '../../config/routes.config';

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

const CountWidget = ({ title, total, color, icon, navLink, sx }) => (
  <>
    {typeof total === 'number' && (
      <Card
        sx={{
          py: 6,
          boxShadow: 0,
          textAlign: 'center',
          color: (theme) => theme.palette[color].darker,
          bgcolor: (theme) => theme.palette[color].lighter,
          ...sx,
        }}
      >
        <StyledIcon
          sx={{
            color: (theme) => theme.palette[color].black,
            backgroundImage: (theme) =>
              `linear-gradient(135deg, ${alpha(
                theme.palette[color].dark,
                0
              )} 0%, ${alpha(theme.palette[color].dark, 0.64)} 60%)`,
          }}
        >
          <Iconify icon={icon} width={24} height={24} />
        </StyledIcon>

        <Typography variant="h3">{formatNumber(total || 0)}</Typography>

        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
          <NavLink to={navLink}>
            <Button>
              <Box sx={{ color: 'black' }}>{title}</Box>
            </Button>
          </NavLink>
        </Typography>
      </Card>
    )}

    {typeof total === 'object' && (
      <Card
        sx={{
          py: 4,
          boxShadow: 0,
          textAlign: 'center',
          minHeight: '268px',
          color: (theme) => theme.palette[color].darker,
          bgcolor: (theme) => theme.palette[color].lighter,
          ...sx,
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{
            opacity: 0.72,
            display: 'inline-block',
            fontSize: '25px',
            marginTop: '-5%',
            marginBottom: '3%',
          }}
        >
          {title}
        </Typography>

        <Divider variant="middle" sx={{ marginBottom: '2%' }} />

        {total.map((item) => (
          <Typography
            key={item.slug}
            variant="subtitle2"
            sx={{ opacity: 0.72 }}
          >
            <NavLink to={`${ROUTE_PATH.CONTENT_CATEGORIES}`}>
              <Button>
                <Box sx={{ fontSize: '16px', color: '#04297A' }}>
                  {item.tabName}
                  {' - '}
                  {formatNumber(item.cnt || 0)}
                </Box>
              </Button>
            </NavLink>
          </Typography>
        ))}
      </Card>
    )}
  </>
);

CountWidget.propTypes = {
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  color: PropTypes.string,
  icon: PropTypes.string,
  navLink: PropTypes.string,
  sx: PropTypes.object,
};
CountWidget.defaultProps = {
  color: 'primary',
  icon: '',
  navLink: '',
  sx: {},
};

export default CountWidget;
