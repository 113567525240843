import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled, alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Divider,
  Typography,
  MenuItem,
  Avatar,
  Popover,
} from '@mui/material';
import ROUTE_PATH from '../../config/routes.config';
import {
  LS_ADMIN_USER_EMAIL,
  LS_ADMIN_USER_NAME,
} from '../../config/const.config';
import { bgBlur } from '../../theme/cssStyles';
import { getLocalValue } from '../../utils/local-cache.util';
import nameInitials from '../../utils/name-initials.util';
import Iconify from '../common/iconify.component';

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
  borderBottom: '1px dashed',
  borderBottomColor: theme.palette.grey[300],
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
  '& h2': {
    color: theme.palette.grey[800],
  },
}));

const Header = ({ pageTitle, onOpenNav }) => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState({
    initials: '',
    name: '',
    email: '',
  });

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    navigate(ROUTE_PATH.LOGOUT);
  };

  useEffect(() => {
    const name = getLocalValue(LS_ADMIN_USER_NAME);
    const email = getLocalValue(LS_ADMIN_USER_EMAIL);
    const initials = nameInitials(name);

    setLoggedInUser({
      ...loggedInUser,
      initials,
      name,
      email,
    });
  }, []);

  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        <h2>{pageTitle}</h2>

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          <IconButton
            onClick={handleOpen}
            sx={{
              p: 0,
              ...(open && {
                '&:before': {
                  zIndex: 1,
                  content: "''",
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                  position: 'absolute',
                  bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                },
              }),
            }}
          >
            <Avatar>{loggedInUser.initials}</Avatar>
          </IconButton>

          <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            sx={{
              p: 0,
              mt: 1.5,
              ml: 0.75,
              width: 220,
            }}
          >
            <Box sx={{ my: 1.5, px: 2.5 }}>
              <Typography variant="subtitle2" noWrap>
                {loggedInUser.name}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: 'text.secondary' }}
                noWrap
              >
                {loggedInUser.email}
              </Typography>
            </Box>

            <Divider sx={{ borderStyle: 'dashed' }} />

            <Stack sx={{ p: 1 }}>
              <MenuItem sx={{ '&:hover': { backgroundColor: '#B1BA03'},}}
                onClick={handleLogout}>
                <Typography variant="body2">Logout</Typography>
              </MenuItem>
            </Stack>
          </Popover>
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
};

Header.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  onOpenNav: PropTypes.func.isRequired,
};

export default Header;
