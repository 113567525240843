import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  Container,
  Paper,
  Stack,
} from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import SnackbarInfo from '../common/snackbar-info.component';
import Iconify from '../common/iconify.component';
import {
  getListSequence,
  updateChannelCategorySequence,
  updateChannelCategoryVideosSequence,
} from '../../services/content.service';
import {
  CONTENT_ALIAS,
  DEFAULT_CHANNEL_VALUE,
} from '../../config/const.config';
import ListSequenceVideos from './list-sequence-videos.component';
import Loading from '../table-elements/loading.component';

const ListSequence = () => {
  const [categoryList, setCategoryList] = useState([]);
  const [categoryVideosList, setCategoryVideosList] = useState([]);
  const [channelID, setChannelID] = useState();
  const [selectedCategory, setSelectedCategory] = useState({
    id: null,
    categoryName: '',
  });
  const [catOptions, setCatOptions] = useState({
    error: false,
    loading: true,
    channelIDError: false,
  });
  const [vidOptions, setVidOptions] = useState({
    error: false,
    loading: true,
  });
  const [action, setAction] = useState({
    show: false,
    data: '',
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  let channel;

  const handleOnDragEndCategories = (result) => {
    if (result.destination) {
      const newCategories = Array.from(categoryList);
      const [draggedItem] = newCategories.splice(result.source.index, 1);
      newCategories.splice(result.destination.index, 0, draggedItem);
      setCategoryList(newCategories);
    }
  };

  const handleOnDragEndVideos = (result) => {
    if (result.destination) {
      const newVideos = Array.from(categoryVideosList);
      const [draggedItem] = newVideos.splice(result.source.index, 1);

      newVideos.splice(result.destination.index, 0, draggedItem);
      setCategoryVideosList(newVideos);
    }
  };

  const handleCloseDialog = () => {
    setAction({ ...action, show: false, data: '' });
    setVidOptions({ ...vidOptions, loading: true });
  };

  const handleUpdateCategorySequence = () => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const updatedCategorySequence = categoryList.map(
      (category) => category.category_id
    );

    const payload = {
      channelID: categoryList[0].channel_id,
      newSequence: updatedCategorySequence,
    };

    updateChannelCategorySequence(payload)
      .then((res) => {
        if (res?.data?.success) {
          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'success',
            message: `${CONTENT_ALIAS.CATEGORY_PLURAL} sequence updated successfully.`,
          });
        }
      })
      .catch(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message: 'Something went wrong.',
        });
      });
  };

  const handleUpdateVideosSequence = () => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const updatedVideosCategorySequence = categoryVideosList.map(
      (video) => video.video_id
    );

    const payload = {
      channelID: categoryVideosList[0].channel_id,
      categoryID: selectedCategory.id,
      newSequence: updatedVideosCategorySequence,
    };

    updateChannelCategoryVideosSequence(payload)
      .then((res) => {
        if (res?.data?.success) {
          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'success',
            message: `${CONTENT_ALIAS.VIDEO_PLURAL} sequence updated successfully.`,
          });
          handleCloseDialog();
        }
      })
      .catch(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message: 'Something went wrong.',
        });
      });
  };

  const handleVideoReordering = (category) => {
    setAction({ ...action, show: true, data: category.id });
    setSelectedCategory({
      ...selectedCategory,
      id: category.id,
      categoryName: category.name,
    });

    let uri = 'video-category';
    uri += `?type=videos`;
    uri += `&channelID=${channelID}`;
    uri += `&categoryID=${category.id}`;

    getListSequence(uri)
      .then((res) => {
        setVidOptions({
          ...vidOptions,
          loading: false,
          error: false,
        });
        setCategoryVideosList(res.data);
      })
      .catch(() =>
        setVidOptions({
          ...vidOptions,
          loading: false,
          error: true,
        })
      );
  };

  const getSequenceData = () => {
    let uri = 'category';
    uri += `?channelID=${channel}`;

    getListSequence(uri)
      .then((res) => {
          setCatOptions({
            ...catOptions,
            loading: false,
            error: false,
          });
          setCategoryList(res.data);
      })
      .catch(() => {
        setCatOptions({
          ...catOptions,
          loading: false,
          error: true,
        });
        setCategoryList([]);
      });
  };

  useEffect(() => {
    // TODO:
    // Currently assume that channelID is not received
    // Add logic to handle checking channelID from database and its execution

    if (DEFAULT_CHANNEL_VALUE) {
      channel = DEFAULT_CHANNEL_VALUE;
      setChannelID(DEFAULT_CHANNEL_VALUE);
      setCatOptions({ ...catOptions, channelIDError: false });
    } else {
      setCatOptions({ ...catOptions, channelIDError: true });
    }

    getSequenceData();
  }, []);

  return (
    <>
      {catOptions.loading && <Loading />}

      {catOptions.channelIDError && <Paper>Something went wrong.</Paper>}

      {!catOptions.channelIDError && (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ px: 3, paddingBottom: 2 }}
          >
            <div>&nbsp;</div>
            <div>
              <Button
                color="primary"
                variant="contained"
                startIcon={<Iconify icon="prime:save" />}
                onClick={() => handleUpdateCategorySequence()}
                disabled={
                  categoryList.length === 0 || categoryList.length === 1
                }
              >
                Save
              </Button>
            </div>
          </Stack>

          <Container
            maxWidth="false"
            sx={{ maxHeight: '74vh', overflowY: 'auto', paddingX: 0 }}
          >
            <DragDropContext onDragEnd={handleOnDragEndCategories}>
              <Droppable droppableId="categories" isDropDisabled={false}>
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {!catOptions.loading &&
                      categoryList.length > 0 &&
                      categoryList.map((category, index) => (
                        <Draggable
                          key={`category-${category.id}`}
                          draggableId={category.id.toString()}
                          index={index}
                        >
                          {(provide) => (
                            <Card
                              sx={{
                                marginBottom: '5px',
                              }}
                              ref={provide.innerRef}
                              {...provide.draggableProps}
                              {...provide.dragHandleProps}
                            >
                              <CardContent
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  paddingY: '12px',
                                  '&:last-child': {
                                    paddingBottom: '12px',
                                  },
                                }}
                              >
                                <div>{category.name}</div>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Button
                                    style={{
                                      marginLeft: 10,
                                      marginRight: 10,
                                    }}
                                    color="inherit"
                                    onClick={() =>
                                      handleVideoReordering(category)
                                    }
                                  >
                                    {`Reorder ${CONTENT_ALIAS.VIDEO_PLURAL} Sequence`}
                                  </Button>
                                  <Iconify icon="ic:round-table-rows" />
                                </div>
                              </CardContent>
                            </Card>
                          )}
                        </Draggable>
                      ))}

                    {!catOptions.loading && categoryList.length === 0 && (
                      <Paper
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '10vh',
                        }}
                      >
                        No data available.
                      </Paper>
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>

              {action.show && (
                <ListSequenceVideos
                  handleOnDragEndVideos={handleOnDragEndVideos}
                  handleUpdateVideosSequence={handleUpdateVideosSequence}
                  action={action}
                  selectedCategory={selectedCategory}
                  categoryVideosList={categoryVideosList}
                  vidOptions={vidOptions}
                  handleCloseDialog={handleCloseDialog}
                />
              )}
            </DragDropContext>
          </Container>
        </>
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};


export default ListSequence;