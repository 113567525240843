import React from 'react';
import AppLayout from '../../layout/app.layout';
import Settings from '../../components/content/settings.component';
import PAGE_TITLE_LIST from '../../config/page-title.config';

const ContentSettingsView = () => {
  const pageTitle = PAGE_TITLE_LIST.CONTENT_SETTINGS;

  return (
    <AppLayout pageTitle={pageTitle}>
      <Settings />
    </AppLayout>
  );
};

export default ContentSettingsView;
