import React from 'react';
import { Grid } from '@mui/material';
import AppLayout from '../../layout/app.layout';
import Counters from '../../components/reports/counters.component';
import PAGE_TITLE_LIST from '../../config/page-title.config';

const DashboardView = () => {
  const pageTitle = PAGE_TITLE_LIST.OVERVIEW_DASHBOARD;

  return (
    <AppLayout pageTitle={pageTitle}>
      <Counters />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          {/* {SHOW_REPORTS_OVERVIEW_VIDEOS && <WatchedSecondsChart />} */}
        </Grid>
      </Grid>
    </AppLayout>
  );
};

export default DashboardView;
