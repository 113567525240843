import React, { useState } from 'react';
import { Stack } from '@mui/material';
import UpdateSettings from './add-edit-settings.component';
import SnackbarInfo from '../common/snackbar-info.component';

const Settings = () => {
  const [dataId, setDataId] = useState(0);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const handleActionSuccess = (message = '') => {
    if (message) {
      setSnackbarInfo({
        ...snackbarInfo,
        show: true,
        type: 'success',
        message,
      });
    }
  };

  return (
    <>
      <Stack direction="row" justifyContent="flex-end" sx={{ px: 1, py: 2 }} />

      <UpdateSettings
        dataId={dataId}
        setDataId={setDataId}
        onSuccess={handleActionSuccess}
      />

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

export default Settings;
