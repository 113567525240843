// Define application wide routes
const ROUTE_PATH = {
  LOGIN: '/login',
  DASHBOARD: '/',
  CONTENT_CHANNELS: '/content/channels',
  CONTENT_CATEGORIES: '/content/categories',
  CONTENT_VIDEOS: '/content/videos',
  CONTENT_SEQUENCE: '/content/sequence',
  CONTENT_SETTINGS: '/content/settings',
  ADMIN_USERS: '/users',
  REPORTS_OVERVIEW_VIDEOS: '/reports/overview/videos',
  LOGOUT: '/logout',
};

export default ROUTE_PATH;
