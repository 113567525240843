import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Stack } from '@mui/material';
import { NavLink } from 'react-router-dom';
import Iconify from '../common/iconify.component';
import ListData from '../table-elements/list-data.component';
import {
  LISTING_ACTIONS,
  LISTING_COLUMNS,
} from '../../config/module-configs/categories.config';
import {
  deleteCategoryById,
  getDataList,
  toggleStatus,
} from '../../services/content.service';
import {
  ACTIONS,
  CONTENT_ALIAS,
} from '../../config/const.config';
import AddEditCategories from './add-edit-categories.component';
import ViewCategories from './view-categories.component';
import ConfirmPopup from '../common/confirm-popup.component';
import SnackbarInfo from '../common/snackbar-info.component';
import ROUTE_PATH from '../../config/routes.config';
import SearchList from '../table-elements/search-list.component';

const ListCategories = ({ categoryTypeName }) => {
  const columns = LISTING_COLUMNS;
  const actions = LISTING_ACTIONS;
  const searchCategoriesRef = useRef(null);
  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    q: null,
    error: false,
    loading: true,
    searchValue: '',
  });
  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
const [previousContentType, setPreviousContentType] =
    useState(categoryTypeName);
  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleSortingChange = (sortBy, sortOrder) => {
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
      q: searchCategoriesRef?.current?.value || '',
    });
  };

  const handleActionSelect = (selectedAction, selectedData) => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    setDoAction({
      ...doAction,
      data: selectedData || null,
      action: selectedAction || null,
    });
  };

  const handleActionCancel = () => {
    setDoAction({
      ...doAction,
      data: null,
      action: null,
    });

  };

  const handleActionSuccess = (message = '') => {
    if (message) {
      setSnackbarInfo({
        ...snackbarInfo,
        show: true,
        type: 'success',
        message,
      });
    }
    handleActionCancel();
    handleRefreshData();
  };

  const handleDeleteData = () => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    deleteCategoryById(doAction.data.id)
      .then(() => {
        handleActionSuccess(
          `${CONTENT_ALIAS.CATEGORY_SINGULAR} deleted successfully.`
        );
        handleActionCancel();
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        handleActionCancel();
      });
  };

  const handleToggleStatus = (r, value, field) => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
   if (field === "showAsFeatured"){
        const payload  = {
        id: r.id,
        status: value ? 0 : 1,
        field: "showAsFeatured",
        type: 'category',
        };

        toggleStatus(payload)
        .then(() => {
            setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'success',
            message: 'ShowAsFeatured updated.',
            });
        })
        .catch((e) => {
            setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'error',
            message: e.response?.data?.message,
            });
        });
    }else {
        const payload = {
        id: r.id,
        status: value ? 0 : 1,
        field: "status",
        type: 'category',
        };
        toggleStatus(payload)
        .then(() => {
            setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'success',
            message: 'Status updated.',
            });
        })
        .catch((e) => {
            setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'error',
            message: e.response?.data?.message,
            });
        });
    }  
  };

  const handleSearchChange = (e) => {
    setOptions({
      ...options,
      searchValue: e.target.value || '',
    });
  };

  const handleSearchCategories = () => {
    setOptions({
      ...options,
      q: searchCategoriesRef?.current?.value || '',
      reloadCounter: options.reloadCounter + 1,
      page: 0,
    });
  };

  const handleClearSearch = () => {
    setOptions({
      ...options,
      searchValue: '',
      q: null,
      reloadCounter: options.reloadCounter + 1,
    });
    searchCategoriesRef.current.value = '';
  };

  const dataListAPICall = () => {
    let uri = 'categories';
    uri += `?type='category'`;
    uri += `&page=${options.page + 1}`;
    uri += `&perPage=${options.rowsPerPage}`;
    if (options.sortBy && options.sortOrder) {
      uri += `&sortBy=${options.sortBy}`;
      uri += `&sortOrder=${options.sortOrder}`;
    }
    if (options.q) {
      uri += `&q=${options.q}`;
    }

    setOptions({ ...options, loading: true, searchValue: '' });

    getDataList(uri)
      .then((res) => {
        let serialRow;
          if (res?.data?.rows) {
            serialRow = res.data.rows;
            serialRow = serialRow.map((tr, count) => ({
              ...tr,
              serial: options.page * options.rowsPerPage + count + 1,
            }));
          }

          setOptions({
            ...options,
            loading: false,
            totalRows: res?.data?.totalRows || 0,
            rows: serialRow || [],
            error: false,
          });
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
        });
      });
  };

  useEffect(() => {
    if (previousContentType !== categoryTypeName) {
        options.page = 0;
        options.rows = [];
        options.totalRows = 0;
        options.loading = true;
        options.q = null;
        options.sortBy = null;
        options.searchValue = '';
        setOptions({
        ...options,
            page: 0,
            rows: [],
            totalRows: 0,
            q: null,
            searchValue: '',
        });
        setPreviousContentType(categoryTypeName);
        if (searchCategoriesRef?.current?.value) {
        searchCategoriesRef.current.value = null;
        }
    }
    dataListAPICall();
  }, [options.reloadCounter]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 1, py: 2 }}
      >
        <div>
          <Button
            color="primary"
            variant="contained"
            startIcon={<Iconify icon="ic:round-add" />}
            onClick={() => handleActionSelect(ACTIONS.CREATE.value, null)}
          >
            Create New
          </Button>
         <NavLink to={ROUTE_PATH.CONTENT_SEQUENCE}
          >
            <Button
              color="primary"
              variant="contained"
              startIcon={<Iconify icon="pepicons-pop:down-up" />}
              style={{ marginLeft: 5 }}
            >
              Manage sequence
            </Button>
          </NavLink>

          <SearchList
            label={CONTENT_ALIAS.CATEGORY_PLURAL}
            handleSearch={handleSearchCategories}
            inputRef={searchCategoriesRef}
            onChange={handleSearchChange}
            options={options}
            clearSearch={handleClearSearch}
          />
        </div>
        <div>
          <Button
            color="inherit"
            startIcon={<Iconify icon="ic:twotone-refresh" />}
            onClick={handleRefreshData}
          >
            Refresh
          </Button>
        </div>
      </Stack>

      <Card>
        <ListData
          columns={columns}
          rows={options.rows}
          page={options.page}
          rowsPerPage={options.rowsPerPage}
          totalRows={options.totalRows}
          loading={options.loading}
          actions={actions}
          error={options.error}
          sortBy={options.sortBy}
          sortOrder={options.sortOrder}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          onSortChange={handleSortingChange}
          onAction={handleActionSelect}
          handleToggleStatus={handleToggleStatus}
        />
      </Card>

      {doAction.action === ACTIONS.CREATE.value && (
        <AddEditCategories
          title={`Add New ${CONTENT_ALIAS.CATEGORY_SINGULAR}`}
          dataId={0}
          categoryTypeName={categoryTypeName}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {doAction.action === ACTIONS.EDIT.value && (
        <AddEditCategories
          title={`Edit ${CONTENT_ALIAS.CATEGORY_SINGULAR} Details - ${doAction.data.title}`}
          dataId={doAction.data.id}
          categoryTypeName={categoryTypeName}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {doAction.action === ACTIONS.VIEW.value && (
        <ViewCategories
          title={`${CONTENT_ALIAS.CATEGORY_SINGULAR} Details - ${doAction.data.title}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
        />
      )}

      {doAction.action === ACTIONS.DELETE.value && (
        <ConfirmPopup
          title={`Delete ${CONTENT_ALIAS.CATEGORY_SINGULAR}`}
          message={`Do you want to delete ${doAction.data.title} ${CONTENT_ALIAS.CATEGORY_SINGULAR}? You can not undo this action!`}
          onCancel={handleActionCancel}
          onSuccess={handleDeleteData}
        />
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

ListCategories.propTypes = {
  categoryTypeName: PropTypes.string.isRequired,
};

export default ListCategories;
