// SETUP COLORS
export const APP_THEME = '#000000';

export const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

export const PRIMARY = {
  lighter: '#B1BA03',
  light: '#B1BA03',
  main: '#B1BA03',
  contrastText: '#000000',
};

export const SECONDARY = {
  lighter: '#B1BA03',
  light: '#B1BA03',
  main: '#B1BA03',
  contrastText: '#000000',
};

export const INFO = {
  lighter: '#B1BA03',
  light: '#B1BA03',
  main: '#B1BA03',
  contrastText: '#000000',
};

export const SUCCESS = {
  lighter: '#B1BA03',
  light: '#B1BA03',
  main: '#B1BA03',
  contrastText: '#000000',
};

export const WARNING = {
  lighter: '#B1BA03',
  light: '#B1BA03',
  main: '#B1BA03',
  contrastText: '#000000',
};

export const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#B1BA03',
  contrastText: '#000000',
};
