import React from 'react';
import PropTypes from 'prop-types';
import AppLayout from '../../layout/app.layout';
import ListCategories from '../../components/content/list-categories.component';
import PAGE_TITLE_LIST from '../../config/page-title.config';

const ContentCategoriesView = ({ categoryTypeName}) => {
  const pageTitle = `${PAGE_TITLE_LIST.CONTENT_CATEGORY}${categoryTypeName}`;

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListCategories categoryTypeName={categoryTypeName} />
    </AppLayout>
  );
};

ContentCategoriesView.propTypes = {
  categoryTypeName: PropTypes.string,
};
ContentCategoriesView.defaultProps = {
  categoryTypeName: '',
};

export default ContentCategoriesView;
