import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
} from '@mui/material';

const Boolean = ({ id, name, defaultValue, label, onChange, sx, error }) => {
  const BOOLEAN = [
    { id: 1, value: 'Yes' },
    { id: 0, value: 'No' },
  ];

  return (
    <FormControl fullWidth error={error !== ''}>
      <InputLabel id={`field-${id}-select-label`}>{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id={id}
        name={name}
        value={defaultValue}
        label={label}
        onChange={onChange}
        sx={sx}
      >
        {BOOLEAN.map((k) => (
          <MenuItem
            sx={{ '&:hover': { backgroundColor: '#B1BA03' } }}
            key={`${id}-v-${k.id}`}
            value={k.id}
          >
            {k.value}
          </MenuItem>
        ))}
      </Select>
      {error !== '' && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

Boolean.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  sx: PropTypes.shape(),
  error: PropTypes.string,
};
Boolean.defaultProps = {
  sx: {},
  error: '',
};

export default Boolean;
