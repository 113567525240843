const validateSelectedVideoFile = (selectedFiles = null) => {
  let errorMsg = '';
  let i = 0;

  const files = selectedFiles;

  if (!files || files?.length === 0) {
    errorMsg = 'Please select video file to upload.';
  }
  if (files?.length > 1) {
    errorMsg = 'Single video file selection allowed.';
  }

  if (errorMsg === '') {
    const allowedExtensions = /(\.mp4|\.mov|\.wmv|\.flv|\.avi|\.mkv|\.webm)$/i;
    for (; i < files?.length; i += 1) {
      if (!allowedExtensions.exec(files[i]?.name)) {
        errorMsg = 'Invalid file type selected.';
      }
    }
  }

  return errorMsg || null;
};

const validateSelectedImageFile = (selectedFiles = null) => {
  let errorMsg = '';
  let i = 0;

  const files = selectedFiles;

  if (!files || files?.length === 0) {
    errorMsg = 'Please select image file to upload.';
  }
  if (files?.length > 1) {
    errorMsg = 'Single image file selection allowed.';
  }

  if (errorMsg === '') {
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    for (; i < files?.length; i += 1) {
      if (!allowedExtensions.exec(files[i]?.name)) {
        errorMsg = 'Invalid file type selected.';
      }
    }
  }

  return errorMsg || null;
};

const validateSelectedSrtFile = (selectedFiles = null) => {
  let errorMsg = '';
  let i = 0;

  const files = selectedFiles;

  if (!files || files?.length === 0) {
    errorMsg = 'Please select SRT file to upload.';
  }

  if (errorMsg === '') {
    const allowedExtensions = /(\.srt|\.vtt)$/i;
    for (; i < files?.length; i += 1) {
      if (!allowedExtensions.exec(files[i]?.name)) {
        errorMsg = 'Invalid file type selected.';
      }
    }
  }

  return errorMsg || null;
};
export {
  validateSelectedVideoFile,
  validateSelectedImageFile,
  validateSelectedSrtFile,
};
