import { CONTENT_ALIAS } from './const.config';

// Define page-title configurations
const PAGE_TITLE_LIST = {
  OVERVIEW_DASHBOARD: 'Overview Dashboard',
  ADMIN_USERS: 'Users',
  CONTENT_CHANNELS: 'Content / Channels',
  CONTENT_CATEGORY: `Content / ${CONTENT_ALIAS.CATEGORY_PLURAL}`,
  CONTENT_VIDEOS: `Content / ${CONTENT_ALIAS.VIDEO_PLURAL}`,
  CONTENT_SEQUENCE: 'Content / Sequence',
  CONTENT_SETTINGS: 'Content / Settings',
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  ERROR_NOT_FOUND: '404 Not Found',
};

export default PAGE_TITLE_LIST;
