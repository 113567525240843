import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  DialogActions,
  Button,
  Grid,
  Box,
  CircularProgress,
  Card,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Dropzone from 'react-dropzone';
import { validateSelectedSrtFile } from '../../utils/file-validations.util';
import {
  deleteSrtToBunnyCDN,
  uploadBulkSrtToBunnyCDN,
  viewCaptionsById,
} from '../../services/content.service';
import ListData from '../table-elements/list-data.component';
import ConfirmPopup from '../common/confirm-popup.component';
import {
  LISTING_COLUMNS,
  LISTING_ACTIONS,
} from '../../config/module-configs/srt.config';
import { ACTIONS } from '../../config/const.config';
import LANGUAGE_LIST from '../../config/module-configs/language.config';


const SrtBulkUpload = ({
  dataId,
  dataTitle,
  showToastMsg,
  setSnackbarInfo,
  onSuccess,
  bulkUpload = false,
}) => {
  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    q: null,
    error: false,
    loading: true,
    searchValue: '',
  });
  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });
  const [selectedFileName, setSelectedFileName] = useState('');
  const [selectedFilesArr, setSelectedFilesArr] = useState([]);
  const [showUploading, setShowUploading] = useState(false);
  const [hasFileTypeError, setHasFileTypeError] = useState(false);
  const columns = LISTING_COLUMNS;
  const actions = LISTING_ACTIONS;

  // eslint-disable-next-line no-promise-executor-return
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const validateSelectedFile = (selectedFiles) => {
    let error = false;
    error = validateSelectedSrtFile(selectedFiles, bulkUpload);
    return error;
  };

  const handleSelectFiles = async (files) => {
    setHasFileTypeError(false);
    const selectedFiles = files;

    setSelectedFilesArr(selectedFiles);
    setSnackbarInfo({
      show: false,
    });
    await sleep(100);

    const validationError = validateSelectedFile(selectedFiles);
    if (validationError) {
      setHasFileTypeError(true);
      showToastMsg('error', validationError);
    }

    setSelectedFileName(selectedFiles.map((key) => key.name).join(', '));
  };

  const handleUploadFiles = async (videoId) => {
    setSnackbarInfo({
      show: false,
    });
    await sleep(100);

    const validationError = validateSelectedFile(selectedFilesArr);
    if (validationError) {
      showToastMsg('error', validationError);
    //   setDisableBtn(false);
    } else {
      setShowUploading(true);
      try {
        const files = selectedFilesArr;
        const srtFormData = new FormData();
        for (let loop = 0; loop < files.length; loop += 1) {
          const langCode = bulkUpload ? files[loop].name.split('_')[0].toUpperCase() : 'EN';
          const langName = LANGUAGE_LIST[langCode.toLowerCase()];
          srtFormData.append('files[]', files[loop]);
          srtFormData.append(
            'filesArr[]',
            JSON.stringify({
              fileName: files[loop].name,
              language: langCode.toLowerCase(),
              videoId: Number(videoId),
              type: 'srt',
              languageCode: langCode.toLowerCase(),
              languageName: langName.toLowerCase(),
            })
          );
        }

        const fileUploadHeader = await uploadBulkSrtToBunnyCDN(
          srtFormData,
          bulkUpload
        );

        if (Number(fileUploadHeader.data.filesUploaded) > 0) {
          setSelectedFilesArr([]);
          setSelectedFileName('');
          onSuccess(
            `${fileUploadHeader.data.filesUploaded} ${dataTitle} file${
              fileUploadHeader.data.filesUploaded > 1 ? 's' : ''
            } uploaded sucessfully`
          );
          setOptions({
            ...options,
            reloadCounter: options.reloadCounter + 1,
          });
          showToastMsg('success', 'file uploaded sucessfully');
        } else {
          showToastMsg('error', 'Something went wrong! Please try again.');
        }
      } catch {
        showToastMsg('error', 'Something went wrong! Please try again.');
      }
      setShowUploading(false);
    }
  };

  const handleFileNewTab = (fileUrl) => {
    window.open(`${fileUrl}?view=1`, '_blank');
  };

  const handleActionSelect = (selectedAction, selectedData) => {
    setDoAction({
      ...doAction,
      data: selectedData || null,
      action: selectedAction || null,
    });
  };

  const handleActionCancel = () => {
    setDoAction({
      ...doAction,
      data: null,
      action: null,
    });
  };

  const handleDeleteFiles = async () => {
    setSnackbarInfo({
      show: false,
    });
    const payload = {
      videoId: dataId,
      languageCode: doAction.data.code,
    };

    try {
      const cdnResponse = await deleteSrtToBunnyCDN(payload);

      if (cdnResponse.data?.success) {
        setSelectedFilesArr([]);
        setSelectedFileName('');
        showToastMsg('success', `File Deleted Successfully!`);
        setOptions({
          ...options,
          reloadCounter: options.reloadCounter + 1,
        });
        handleActionCancel();
      } else {
        handleActionCancel();
        showToastMsg('error', 'Something went wrong! Please try again.');
      }
    } catch {
      showToastMsg('error', 'Something went wrong! Please try again.');
    }
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const LinearProgressWithLabel = (props) => {
    const { value } = props;

    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  };

  LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
  };

  useEffect(() => {
    if (bulkUpload) {
      viewCaptionsById(dataId)
        .then((res) => {
          setOptions({
            ...options,
            loading: false,
            totalRows: res?.data?.totalRows || 0,
            rows: res?.data?.rows || [],
            error: false,
          });
        })
        .catch((err) => {
          setOptions({
            ...options,
            loading: false,
            page: 0,
            totalRows: 0,
            rows: [],
            error: true,
          });

          const message =
            err.response?.data?.message ||
            'Something went wrong, please try again.';

          setSnackbarInfo({
            show: true,
            type: 'error',
            message,
          });
        });
    }
  }, [dataId, options.reloadCounter]);

  return (
    <Grid container spacing={2}>
      <Grid
        item
        display="flex"
        xs={12}
        sm={bulkUpload ? 9 : 12}
        md={bulkUpload ? 9 : 12}
        direction={bulkUpload ? 'column' : 'row'}
        margin="auto"
      >
        <Grid item xs={12} sm={8} md={8}>
          <Grid item xs={12} sm={12} md={12}>
            <Dropzone onDrop={(files) => handleSelectFiles(files)}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />

                    <Box
                      component="section"
                      sx={{
                        p: 3,
                        border: '1px dashed grey',
                        borderRadius: '20px',
                        width: '100%',
                        marginTop: 3,
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            fontWeight: 'bold',
                          }}
                        >
                          Add/Replace {dataTitle} file
                        </Grid>
         
                        <Grid item xs={12} sm={12} md={12}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              textAlign: 'center',
                            }}
                          >
                            Drag and drop SRT files here, or click to
                            browse
                          </Grid>
                          <DialogActions sx={{ justifyContent: 'center' }}>
                            {!showUploading && (
                              <Button
                                component="label"
                                variant="contained"
                                color="primary"
                              >
                                Select SRT File
                              </Button>
                            )}

                            {showUploading && (
                              <Box
                                display="flex"
                                alignItems="center"
                                padding="6px 16px"
                                borderRadius="6px"
                                sx={{
                                  backgroundColor: '#919eab3d',
                                  color: '#919eabcc',
                                }}
                              >
                                <CircularProgress
                                  disableShrink
                                  size="18px"
                                  sx={{ marginRight: 1, color: '#919eabcc' }}
                                />
                                <span>Uploading...</span>
                              </Box>
                            )}
                          </DialogActions>

                          {bulkUpload && (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                textAlign: 'center',
                              }}
                            >
                              Please upload SRT files with language
                              specific prefix &#40;e.g: en_filename
                              srt
                              &#41;
                            </Grid>
                          )}
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        {`${selectedFileName}`}
                      </Grid>
                    </Box>
                  </div>
                </section>
              )}
            </Dropzone>
          </Grid>
             <DialogActions sx={{ justifyContent: 'center' }}>
              <Button
                color="primary"
                variant="contained"
                disabled={showUploading || hasFileTypeError}
                onClick={() => handleUploadFiles(dataId, dataTitle)}
              >
                Upload
              </Button>
            </DialogActions>
        </Grid>
      </Grid>

      {bulkUpload && (
        <Grid item xs={12} sm={12} md={12}>
          <Card>
            <Grid item sx={{ marginTop: 2 }}>
              List of {dataTitle} files:
            </Grid>

            <ListData
              columns={columns}
              rows={options.rows}
              page={options.page}
              rowsPerPage={options.totalRows}
              totalRows={options.totalRows}
              loading={options.loading}
              actions={actions}
              error={options.error}
              sortBy={options.sortBy}
              sortOrder={options.sortOrder}
              onPageChange={() => {}}
              onRowsPerPageChange={() => {}}
              onSortChange={() => {}}
              onAction={handleActionSelect}
              onCustomClick={handleFileNewTab}
            />
          </Card>

          {doAction.action === ACTIONS.DELETE.value && (
          <ConfirmPopup
              title="Delete SRT File for respective language"
              message="Do you want to delete SRT file? You can not undo this action!"
              onCancel={handleActionCancel}
              onSuccess={handleDeleteFiles}
          />
          )}
        </Grid>
      )}
    </Grid>
  );
};

SrtBulkUpload.propTypes = {
  dataId: PropTypes.number.isRequired,
  dataTitle: PropTypes.string.isRequired,
  showToastMsg: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  setSnackbarInfo: PropTypes.object.isRequired,
  bulkUpload: PropTypes.bool.isRequired,
};

export default SrtBulkUpload;
