import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  Stack,
  Chip,
} from '@mui/material';
import { getSearchListCategories } from '../../services/content.service';
import { MULTIPLE_CAT_PER_VIDEO } from '../../config/const.config';

const Categories = ({ id, name, defaultValue, label, onChange, sx, error }) => {
  const selectedValue = [];

  if (MULTIPLE_CAT_PER_VIDEO) {
    if (typeof defaultValue === 'object') {
      defaultValue.forEach((item) => {
        selectedValue.push(item);
      });
    }
  }

  const [booksData, setBooksData] = useState([]);

  useEffect(() => {
    getSearchListCategories()
      .then((res) => {
        setBooksData(res.data);
      })
      .catch(() => {
        // nothing
      });
  }, []);


  return (
    <FormControl fullWidth error={error !== ''}>
      <InputLabel id={`field-${id}-select-label`}>{label}</InputLabel>

      {MULTIPLE_CAT_PER_VIDEO && (
        <Select
          multiple
          labelId="demo-simple-select-label"
          id={id}
          name={name}
          value={selectedValue}
          label={label}
          onChange={onChange}
          sx={sx}
          renderValue={(selected) => (
            <Stack gap={1} direction="row" flexWrap="wrap">
              {selected.map((value) => (
                <Chip key={value} label={value.split('] - ')[1]} />
              ))}
            </Stack>
          )}
        >
          {booksData.map((k) => (
            <MenuItem sx={{ '&:hover': { backgroundColor: '#B1BA03'},}}
              key={`${id}-v-${k.id}`}
              value={`[${k.id}] - ${k.title}`}
            >
              {`${k.title}`}
            </MenuItem>
          ))}
        </Select>
      )}

      {error !== '' && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

Categories.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  sx: PropTypes.shape(),
  error: PropTypes.string,
};
Categories.defaultProps = {
  sx: {},
  error: '',
};

export default Categories;
