import React, { useEffect, useState } from 'react';
import { Button, TextField, Grid } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import validationRules, {
  maxLengthValidation,
} from '../../utils/validations.util';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  viewSettingsById,
  addUpdateSettings,
} from '../../services/content.service';
import {
  CONTENT_ALIAS,
  DEFAULT_CHANNEL_VALUE,
} from '../../config/const.config';
import Boolean from '../form-elements/boolean.component';

const UpdateSettings = () => {
  const channelId = DEFAULT_CHANNEL_VALUE;
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      externalApiWebHookURL: '',
      externalVastURL: '',
      allowPreroll: null,
      allowMidroll: null,
      allowPostroll: null,
    },
  });

  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const addEditSettingsData = (payload) => {
    addUpdateSettings(payload)
      .then(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: `${CONTENT_ALIAS.SETTINGS} details updated successfully.`,
        });
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
      });
    setFormSubmitted(false);
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      externalApiWebHookURL: data.externalApiWebHookURL,
      externalVastURL: data.externalVastURL,
      allowPreroll: data.allowPreroll || 0,
      allowMidroll: data.allowMidroll || 0,
      allowPostroll: data.allowPostroll || 0,
      midrollInterval: data.midrollInterval,
      channelId,
    };

    addEditSettingsData(payload);
  };

  useEffect(() => {
    viewSettingsById(channelId)
      .then((res) => {
        // Fill form values
        setValue(
          'externalApiWebHookURL',
          res.data?.externalApiWebHookURL || ''
        );
        setValue('externalVastURL', res.data?.externalVastURL || '');
        setValue('allowPreroll', res.data?.allowPreroll || 0);
        setValue('allowMidroll', res.data?.allowMidroll || 0);
        setValue('allowPostroll', res.data?.allowPostroll || 0);
        setValue('midrollInterval', res.data?.midrollInterval || 0);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [channelId]);

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
              <Controller
                name="externalApiWebHookURL"
                control={control}
                rules={{
                  ...validationRules.REQUIRED,
                  ...{ ...maxLengthValidation(1500) },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="External Get WebHook API Url"
                    type="text"
                    error={!!errors.externalApiWebHookURL}
                    helperText={errors?.externalApiWebHookURL?.message || null}
                    fullWidth
                    multiline
                    rows={4}
                    inputProps={{ maxLength: 1500 }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Controller
                name="externalVastURL"
                control={control}
                rules={{
                  ...{ ...maxLengthValidation(1500) },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="External VAST Url"
                    type="text"
                    error={!!errors.externalVastURL}
                    helperText={errors?.externalVastURL?.message || null}
                    fullWidth
                    multiline
                    rows={4}
                    inputProps={{ maxLength: 1500 }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <Controller
                name="allowPreroll"
                control={control}
                rules={{
                  ...validationRules.REQUIRED,
                }}
                render={({ field: { onChange, value } }) => (
                  <Boolean
                    id="allowPreroll"
                    name="allowPreroll"
                    label="Allow Preroll"
                    defaultValue={value}
                    onChange={onChange}
                    sx={{ width: '100%' }}
                    error={errors?.allowPreroll?.message || ''}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <Controller
                name="allowMidroll"
                control={control}
                rules={{
                  ...validationRules.REQUIRED,
                }}
                render={({ field: { onChange, value } }) => (
                  <Boolean
                    id="allowMidroll"
                    name="allowMidroll"
                    label="Allow Midroll"
                    defaultValue={value}
                    onChange={onChange}
                    sx={{ width: '100%' }}
                    error={errors?.allowMidroll?.message || ''}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <Controller
                name="allowPostroll"
                control={control}
                rules={{
                  ...validationRules.REQUIRED,
                }}
                render={({ field: { onChange, value } }) => (
                  <Boolean
                    id="allowPostroll"
                    name="allowPostroll"
                    label="Allow Postroll"
                    defaultValue={value}
                    onChange={onChange}
                    sx={{ width: '100%' }}
                    error={errors?.allowPostroll?.message || ''}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <Controller
                name="midrollInterval"
                control={control}
                rules={{
                  ...{ ...maxLengthValidation(5) },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Midroll Interval (in seconds)"
                    type="number"
                    error={!!errors.midrollInterval}
                    helperText={errors?.midrollInterval?.message || null}
                    fullWidth
                    inputProps={{ maxLength: 5, min: 0, max: 65535 }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      )}
      <Button
        type="submit"
        form="add-edit-form"
        color="primary"
        variant="contained"
        disabled={formSubmitted}
        sx={{ mt: 3 }}
      >
        Update Settings
      </Button>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

export default UpdateSettings;
