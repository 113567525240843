import React from 'react';
import PropTypes from 'prop-types';
import AppLayout from '../../layout/app.layout';
import ListSequence from '../../components/content/list-sequence.component';
import PAGE_TITLE_LIST from '../../config/page-title.config';

const ContentSequenceView = ({ categoryName }) => {
  const pageTitle = `${PAGE_TITLE_LIST.CONTENT_SEQUENCE}${categoryName}`;

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListSequence />
    </AppLayout>
  );
};

ContentSequenceView.propTypes = {
  categoryName: PropTypes.string,
};
ContentSequenceView.defaultProps = {
  categoryName: '',
};

export default ContentSequenceView;
