import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

// Categories module configurations
export const LISTING_COLUMNS = [
  {
    id: 'title',
    label: 'Title',
    width: '20%',
    align: 'left',
    dataKey: 'title',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'description',
    label: 'Description',
    width: '15%',
    align: 'left',
    dataKey: 'long_description',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'showAsFeatured',
    label: 'ShowAsFeatured',
    width: '15%',
    align: 'left',
    dataKey: 'showAsFeatured',
    sortable: false,
    type: CELL_DATA_TYPES.SWITCH,
  },
  {
    id: 'status',
    label: 'Status',
    width: '10%',
    align: 'center',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.SWITCH,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    width: '15%',
    align: 'right',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.DELETE.value,
    label: ACTIONS.DELETE.label,
  },
];

export const VIEW_CATEGORIES_COLUMNS = [
  {
    id: 'name',
    label: 'Title',
    dataKey: 'name',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'description',
    label: 'Description',
    dataKey: 'description',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'showAsFeatured',
    label: 'ShowAsFeatured',
    dataKey: 'showAsFeatured',
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'status',
    label: 'Status',
    dataKey: 'status',
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];
